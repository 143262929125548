.cnt {
    display: flex;
    width: 100%;
    align-items: stretch;
  }
  
  @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
  
  body {
    font-family: "Poppins", sans-serif;
  /*   background: #fafafa;
   */}
  

  
  /* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
  .sidebar {
    position: fixed; /* Fixes the sidebar relative to the viewport */
    top: 0;
    left: 0;
    height: 100vh; /* Ensures it spans the full viewport height */
    overflow-y: auto; /* Allows scrolling if content overflows */
    z-index: 1000; /* Ensures it stays above other elements */
    min-width: 250px;
    max-width: 250px;
    background: #fafafc;
    color: #fff;
    margin-left: -250px;
    transition: all 0.5s;
  }
  
  .sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
  }
  
  .sidebar-header {
    background: #fafafc;
  }
  
  .sidebar-header h3 {
    color: #fff;
    padding: 1em;
  }
  
  .sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  .menu-open {
    background: #6d7fcc;
  }
  
  .nav-item:hover {
    background: #fff;
  } 
  
  .sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
  .side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
  }
  
  .side-menu::-webkit-scrollbar {
    width: 10px;
  }
  
  .side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
  }
  
  .side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
  }
  
  /* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
  .content {
    /* padding: 20px;
    margin-left: 0; */
    margin-left: 250px; /* Matches the sidebar width */
    transition: margin-left 0.5s; /* Smooth transition when toggling sidebar */
    /*height: 100vh; */
  }

  .content.is-open {
    margin-left: 0; /* Reset margin */
}

  /* Mobile styles for screens less than 768px */
@media only screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; /* Sidebar spans the full width on mobile */
    height: 100vh; /* Full height for scrolling */
    z-index: 1050; /* Keeps it above other elements */
    margin-left: -100%; /* Hidden by default */
    transition: margin-left 0.3s ease-in-out;
  }

  .sidebar.is-open {
    margin-left: 0; /* Slide in the sidebar */
  }

  .sidebar-header > span {
    display: block; /* Show the close (X) button */
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .content {
    margin-left: 0; /* Content spans full width on mobile */
    transition: margin-left 0.3s ease-in-out;
  }

  .nav-item {
    padding: 10px 15px;
    text-align: center;
    font-size: 1rem;
  }
}
  